import * as React from 'react';

export interface AppSettings {
  apiUrl: string;
  stage: string;
  region: string;
  accountId: string;
  rumApplicationId?: string;
  rumRoleArn?: string;
  graphqlApiUrl: string;
}

export const AppSettingsContext = React.createContext<AppSettings | null>(null);

/** Use the AppSettingsContext */
export function useAppSettings(): AppSettings {
  const context = React.useContext(AppSettingsContext);
  if (context === null) {
    throw new Error('useAppSettings must be used within a AppSettingsProvider');
  }
  return context;
}
