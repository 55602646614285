export function copyToClipboard(text: string): Promise<void> {
  try {
    // Try to use the modern clipboard API.
    // Some browsers only allow this API in response to a user initiated event.
    return navigator.clipboard.writeText(text);
  } catch {
    // Fall back to using a textarea. Making it asynchronous to align with clipboard API
    // https://stackoverflow.com/a/30810322/898577
    return new Promise<void>((resolve, reject) => {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        resolve();
      } catch (err) {
        reject(err as Error);
      } finally {
        document.body.removeChild(textArea);
        // TODO: fix as focus doesn't seem to exist on an active element.
        //   activeElement!.focus();
      }
    });
  }
}
