import * as React from 'react';
import { ControlPlaneClientManager } from '../clients/ControlPlaneClientManager';
import { LegacyIamRole, useLegacyIamRoleCredentials } from './LegacyIamRoles';
import { useAppSettings } from './AppSettings';
import { ControlPlaneClientManagerContext } from './ControlPlaneClientManager';
import { useUsername } from './Midway';

/** Used to create and provide a ControlPlaneClientManager to the application */
export function ControlPlaneClientManagerProvider(props: { readonly children: React.ReactNode }) {
  const username = useUsername();
  const { region, stage } = useAppSettings();
  const gsocReadonlyCredentials = useLegacyIamRoleCredentials(LegacyIamRole.AwsGsaasGSOCOpsReadOnly);

  const controlPlaneClientManager = React.useMemo(() => {
    if (gsocReadonlyCredentials === null) return null;
    return new ControlPlaneClientManager({
      credentials: gsocReadonlyCredentials,
      gsocRegion: region,
      stage,
      username,
    });
  }, [gsocReadonlyCredentials, region, stage, username]);

  return (
    <ControlPlaneClientManagerContext.Provider value={controlPlaneClientManager}>
      {props.children}
    </ControlPlaneClientManagerContext.Provider>
  );
}
