import * as React from 'react';
import { S3WithErrorRefinement } from '../utils/s3';
import { buildAwsTemporaryCredentialProvider } from '../clients/buildAwsTemporaryCredentialProvider';
import { GSOC_NAME_PART, accountsHelper } from '../utils/accountsUtils';
import { COMMON_CFN_READ_ONLY_ROLE_NAME, getRoleArn } from '../utils/gsRoleUtils';
import { LegacyIamRole, useLegacyIamRoleCredentials } from './LegacyIamRoles';
import { LegacyAnalyticsAccountS3ClientContext } from './LegacyAnalyticsAccountS3Client';
import { useAppSettings } from './AppSettings';
import { useUsername } from './Midway';

/**
 * S3Client context.
 *
 * Used to create and provide a, S3Client to the application.
 *
 * @param children Nested components
 * @returns
 */
export function LegacyAnalyticsAccountS3ClientProvider(props: { readonly children: React.ReactNode }) {
  const { region, stage } = useAppSettings();
  const username = useUsername();
  const gsocReadonlyCredentials = useLegacyIamRoleCredentials(LegacyIamRole.AwsGsaasGSOCOpsReadOnly);
  const gsocAccountS3Client = React.useMemo(() => {
    if (gsocReadonlyCredentials === null) return null;
    const gsocAccount = accountsHelper.getAccount(GSOC_NAME_PART, stage, region);
    if (!gsocAccount) {
      console.error(new Error(`No gsoc account found for stage ${stage} and region ${region}.`));
      return null;
    }
    const gsocAccountId = gsocAccount.account_id;
    const roleArn = getRoleArn(gsocAccountId, COMMON_CFN_READ_ONLY_ROLE_NAME);

    return new S3WithErrorRefinement({
      credentials: buildAwsTemporaryCredentialProvider({
        roleArn,
        username,
        masterCredentials: {
          accessKeyId: gsocReadonlyCredentials.accessKeyId,
          secretAccessKey: gsocReadonlyCredentials.secretAccessKey,
          sessionToken: gsocReadonlyCredentials.sessionToken,
          expiration: gsocReadonlyCredentials.expiration,
        },
        region,
      }),
      region,
    });
  }, [gsocReadonlyCredentials, region, stage, username]);

  return (
    <LegacyAnalyticsAccountS3ClientContext.Provider value={gsocAccountS3Client}>
      {props.children}
    </LegacyAnalyticsAccountS3ClientContext.Provider>
  );
}
