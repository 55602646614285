import * as React from 'react';
import type {
  GsocIamRoleFragment,
  GsocAwsTemporaryCredentialsFragment,
} from '../code-generated/graphqlOperations.generated';

export enum LegacyIamRole {
  AwsGsaasGSOCOpsReadOnly = 'AwsGsaasGSOCOpsReadOnly',
  AwsGsaasGSOCOpsReadWrite = 'AwsGsaasGSOCOpsReadWrite',
}

export const LegacyIamRolesContext = React.createContext<readonly GsocIamRoleFragment[] | null>(null);

export function useLegacyIamRoleCredentials(
  iamRoleName: LegacyIamRole | string,
): GsocAwsTemporaryCredentialsFragment | null {
  const iamRolesContext = React.useContext(LegacyIamRolesContext);
  if (iamRolesContext === null) {
    throw new Error('useLegacyIamRoleCredentials must be used within an LegacyIamRolesContext');
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  const credentials = iamRolesContext.find(role => role.name === iamRoleName)?.awsTemporaryCredentials;
  return credentials ?? null;
}

export function useLegacyIamRoles(): readonly GsocIamRoleFragment[] {
  const iamRolesContext = React.useContext(LegacyIamRolesContext);
  if (iamRolesContext === null) {
    throw new Error('useLegacyIamRoles must be used within an LegacyIamRolesContext');
  }
  return iamRolesContext;
}
