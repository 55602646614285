import * as React from 'react';
import { TopNavigation } from '@amzn/awsui-components-react';
import { styled } from '@linaria/react';
import { useAppSettings } from '../context/AppSettings';
import { NAV_I18NSTRINGS } from '../constants/i18nStrings';
import { accountsHelper } from '../utils/accountsUtils';
import { useCazJustification } from '../context/Permissions';
import { LOGO_BASE_64 } from './primitives/icons';
import { SettingsModal } from './SettingsModal';
import { ContingentAuthModal } from './ContingentAuthDialog';
import { useModalState } from '../hooks/useModalState';

/** Common header for all pages */
export default function CommonHeader() {
  const { stage, region } = useAppSettings();
  const [cazJustification] = useCazJustification();
  const [settingsModalVisible, settingsModalActions] = useModalState();
  const [cazModalVisible, cazModalActions] = useModalState();

  const regionSelectionItems = React.useMemo(
    () =>
      accountsHelper.getAccounts('opsconsole', stage).map(account => {
        const endpoint = account.services.find(service => service.service_key === 'gsoc')?.endpoint;
        if (endpoint === undefined) {
          throw new Error('Unable to get gsoc account');
        }
        return {
          id: account.region_abbr,
          text: account.region,
          href: `https://${endpoint}/#`,
          variant: 'link',
        };
      }),
    [stage],
  );

  return (
    <>
      <TopNavigation
        identity={{
          href: '/#',
          title: 'AWS Ground Station - Operations Console (GSOC)',
          logo: {
            src: LOGO_BASE_64,
            alt: 'GSOC',
          },
        }}
        utilities={[
          {
            type: 'button',
            text: 'Cut GSOC SIM',
            href: 'https://issues.amazon.com/issues/create?template=0fcdeef1-2dde-4426-98ae-8811e0aed369',
            variant: 'link',
            target: '_blank',
          },
          {
            type: 'menu-dropdown',
            text: window.location.hostname === 'dev-user.amazon.com' ? `dev-user(${region})` : region,
            description: 'Select Ops Console Region',
            items: regionSelectionItems,
          },
          // This is soooo hacky, but it works. Cloudscape doesn't support custom elements in the header (dumb)
          { type: 'button', text: (<UtcTimeStamp />) as unknown as string },
          // This is soooo hacky, but it works. Cloudscape doesn't support custom elements in the header (dumb)
          {
            type: 'button',
            text: (cazJustification !== null ? (
              <CazEnabledSpan>CAZ Enabled</CazEnabledSpan>
            ) : (
              'CAZ'
            )) as unknown as string,
            onClick: cazModalActions.open,
          },
          {
            type: 'button',
            iconName: 'settings',
            onClick: settingsModalActions.open,
          },
        ]}
        i18nStrings={NAV_I18NSTRINGS}
      />
      <ContingentAuthModal visible={cazModalVisible} closeModal={cazModalActions.close} />
      <SettingsModal visible={settingsModalVisible} closeModal={settingsModalActions.close} />
    </>
  );
}

const TimestampSpan = styled.span`
  cursor: text;
  font-weight: normal;
`;

const CazEnabledSpan = styled.span`
  font-weight: bold;
  color: #ff0000;
`;

function UtcTimeStamp() {
  const [utcTime, setUtcTime] = React.useState(new Date());
  React.useEffect(() => {
    const interval = setInterval(() => {
      setUtcTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return <TimestampSpan>{`${utcTime.toISOString().slice(0, -5)}Z`}</TimestampSpan>;
}
