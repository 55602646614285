import { useQuery } from '@tanstack/react-query';
import type * as React from 'react';
import { Button, Icon, Spinner } from '@amzn/awsui-components-react';
import { styled } from '@linaria/react';
import { fetchMidwayToken } from '../datafetchers/fetchMidwayToken';
import { QueryKeys } from '../constants/queryKeys';
import { CenteredFullPageMessage } from '../components/primitives/CenteredFullPageMessage';
import { MidwayContext } from './Midway';

const TIMEOUT_BUFFER_SECONDS = 30;
const RETRY_ATTEMPTS = 3;

const CutTicketButton = styled(Button)`
  margin-left: 5rem;
`;

/** Fetch and provide a midway auth token to the application. */
export function MidwayProvider(props: { readonly children: React.ReactNode }) {
  const midwayQuery = useQuery({
    queryKey: [QueryKeys.MIDWAY],
    queryFn: () => fetchMidwayToken(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: RETRY_ATTEMPTS,
    refetchIntervalInBackground: true,
    refetchInterval: data => {
      const expiration = data?.decodedToken.exp;
      if (expiration !== undefined) {
        return ((expiration * 1000 - Date.now()) / 1000 - TIMEOUT_BUFFER_SECONDS) * 1000;
      }
      return 0;
    },
    onError: console.error,
  });

  return (
    <MidwayContext.Provider value={midwayQuery.data ?? null}>
      {midwayQuery.isLoading ? (
        <CenteredFullPageMessage>
          <Spinner size='large' />
          Loading Midway credentials...
        </CenteredFullPageMessage>
      ) : midwayQuery.isError ? (
        <CenteredFullPageMessage>
          <Icon name='close' size='large' variant='error' />
          <div>
            Unable to load Midway credentials.
            <br />
            Please cut us a ticket for assistance.
          </div>
          <CutTicketButton
            target='_blank'
            href='https://t.corp.amazon.com/create/options?category=AWS&type=GroundStation&item=GSOC'
          >
            Cut Ticket
          </CutTicketButton>
        </CenteredFullPageMessage>
      ) : (
        props.children
      )}
    </MidwayContext.Provider>
  );
}
