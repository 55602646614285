import * as React from 'react';
import type { ControlPlaneClientManager } from '../clients/ControlPlaneClientManager';

export const ControlPlaneClientManagerContext = React.createContext<ControlPlaneClientManager | null>(null);

/**
 * Use the ControlPlaneClientManager
 * @returns ControlPlaneClientManager
 */
export function useControlPlaneClientManager(): ControlPlaneClientManager | null {
  return React.useContext(ControlPlaneClientManagerContext);
}
