import { DynamoDB } from '@aws-sdk/client-dynamodb';
import * as React from 'react';
import { buildAwsTemporaryCredentialProvider } from '../clients/buildAwsTemporaryCredentialProvider';
import { ANALYTICS_NAME_PART, accountsHelper } from '../utils/accountsUtils';
import { DATA_ANALYTICS_READ_ONLY_ROLE_NAME, getRoleArn } from '../utils/gsRoleUtils';
import { LegacyIamRole, useLegacyIamRoleCredentials } from './LegacyIamRoles';
import { AnalyticsAccountDynamoDbClientContext } from './AnalyticsAccountDynamoDbClient';
import { useAppSettings } from './AppSettings';
import { useUsername } from './Midway';

/**
 * DynamoDB context.
 *
 * Used to create and provide a DynamoDB to the application.
 *
 * @param children Nested components
 * @returns
 */
export function AnalyticsAccountDynamoDbClientProvider(props: { readonly children: React.ReactNode }) {
  const { region, stage } = useAppSettings();
  const username = useUsername();
  const gsocReadonlyCredentials = useLegacyIamRoleCredentials(LegacyIamRole.AwsGsaasGSOCOpsReadOnly);

  const analyticsAccountDynamoDbClient = React.useMemo(() => {
    if (gsocReadonlyCredentials === null) return null;
    const analyticAccount = accountsHelper.getAccount(ANALYTICS_NAME_PART, stage, region);
    if (!analyticAccount) {
      console.error(new Error(`No analytics account found for stage ${stage} and region ${region}.`));
      return null;
    }
    const analyticAccountId = analyticAccount.account_id;
    const roleArn = getRoleArn(analyticAccountId, DATA_ANALYTICS_READ_ONLY_ROLE_NAME);

    return new DynamoDB({
      credentials: buildAwsTemporaryCredentialProvider({
        roleArn,
        username,
        masterCredentials: {
          accessKeyId: gsocReadonlyCredentials.accessKeyId,
          secretAccessKey: gsocReadonlyCredentials.secretAccessKey,
          sessionToken: gsocReadonlyCredentials.sessionToken,
          expiration: gsocReadonlyCredentials.expiration,
        },
        region,
      }),
      region,
      apiVersion: '2012-08-10',
    });
  }, [gsocReadonlyCredentials, region, stage, username]);

  return (
    <AnalyticsAccountDynamoDbClientContext.Provider value={analyticsAccountDynamoDbClient}>
      {props.children}
    </AnalyticsAccountDynamoDbClientContext.Provider>
  );
}
