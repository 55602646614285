import * as React from 'react';
import type { GsocIamRoleFragment } from '../code-generated/graphqlOperations.generated';
import { useGsocListIamRolesQuery } from '../code-generated/graphqlOperations.generated';
import { NOT_LOADED, PermissionsContext } from './Permissions';

const TIMEOUT_BUFFER_SECONDS = 30;

export function PermissionsProvider(props: { readonly children: React.ReactNode }) {
  const [cazJustification, setCazJustification] = React.useState<string | null>(null);
  const { data, refetch, loading } = useGsocListIamRolesQuery({
    variables: { cazJustification },
  });

  /** Refetch AwsTemporaryCredentials just before they expire */
  React.useEffect(() => {
    if (data?.gsocListIamRoles != null) {
      const minimumExpiration = data.gsocListIamRoles.roles.reduce((min, role) => {
        const expiration = role.awsTemporaryCredentials?.expiration.getTime();
        if (expiration !== undefined) {
          return (min === 0 && expiration > 0) || expiration < min ? expiration : min;
        }
        return min;
      }, 0);
      const timeoutDuration = ((minimumExpiration - Date.now()) / 1000 - TIMEOUT_BUFFER_SECONDS) * 1000;
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      let timeoutHandle: number | null = window.setTimeout(refetch, timeoutDuration);

      return () => {
        if (timeoutHandle !== null) {
          window.clearTimeout(timeoutHandle);
          timeoutHandle = null;
        }
      };
    }
    return () => {
      /** Intentionally Empty */
    };
  }, [data?.gsocListIamRoles, refetch]);

  /** Clear cazJustification after 1 hour */
  React.useEffect(() => {
    if (cazJustification !== null) {
      const timeoutHandle = window.setTimeout(() => setCazJustification(null), 60 * 60 * 1000);
      return () => {
        window.clearTimeout(timeoutHandle);
      };
    }
    return () => {
      /** Intentionally Empty */
    };
  }, [cazJustification]);

  const providedValue = React.useMemo<{
    roles: readonly GsocIamRoleFragment[] | typeof NOT_LOADED;
    setCazJustification: ((cazJustification: string | null) => void) | null;
    cazJustification: string | null;
  }>(
    () => ({
      roles: loading ? NOT_LOADED : (data?.gsocListIamRoles?.roles ?? []),
      setCazJustification,
      cazJustification,
    }),
    [loading, data?.gsocListIamRoles?.roles, cazJustification, setCazJustification],
  );

  return <PermissionsContext.Provider value={providedValue}>{props.children}</PermissionsContext.Provider>;
}
