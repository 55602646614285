import * as React from 'react';
import type { MgmtCellClientManager } from '../clients/MgmtCellClientManager';
import { PermissionClaim } from '../code-generated/PermissionClaim.generated';

export const MgmtCellClientManagerContext = React.createContext<{
  [PermissionClaim.ReadMaintenance]: MgmtCellClientManager | null;
  [PermissionClaim.ScheduleMaintenance]: MgmtCellClientManager | null;
}>({
  [PermissionClaim.ReadMaintenance]: null,
  [PermissionClaim.ScheduleMaintenance]: null,
});

export function useMgmtCellClientManager(
  permission: PermissionClaim.ReadMaintenance | PermissionClaim.ScheduleMaintenance,
): MgmtCellClientManager | null {
  const clients = React.useContext(MgmtCellClientManagerContext);
  return clients[permission];
}
