import type { Operation, NextLink } from '@apollo/client';
import { ApolloLink } from '@apollo/client';

const DEFAULT_TIMEOUT = 15000;

/** Aborts the request if the timeout expires before the response is received.
 * Requires that the downstream network link utilizes the AbortSignal provided in the request context
 */
export class TimeoutLink extends ApolloLink {
  private timeoutMs: number;

  constructor(options?: { timeoutMs?: number }) {
    super();
    this.timeoutMs = options?.timeoutMs ?? DEFAULT_TIMEOUT;
  }

  public override request(operation: Operation, forward: NextLink) {
    // override timeout from query context
    const requestTimeout = (operation.getContext()['timeoutMs'] as number | undefined) ?? this.timeoutMs;

    operation.setContext({
      fetchOptions: {
        signal: AbortSignal.timeout(requestTimeout),
      },
    });

    return forward(operation);
  }
}
