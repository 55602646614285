import * as React from 'react';
import type { FlashbarProps } from '@amzn/awsui-components-react';
import type { Notification } from './Notifications';
import { AddNotificationsContext, NotificationsContext } from './Notifications';

/**
 * Notifications Provider.
 *
 * Used to provide a notifications api to the application.
 *
 * @param children Nested components
 * @returns
 */
export function NotificationsProvider(props: { readonly children: React.ReactNode }) {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  const notificationId = React.useRef(0);
  // We use a ref here to make sure the addNotification callback is stable through renders/notifications added
  const notificationsRef = React.useRef(notifications);

  // Update the ref everytime our notifications change
  React.useEffect(() => {
    notificationsRef.current = notifications;
  }, [notifications]);

  const addNotification = React.useCallback(
    (args: { type: FlashbarProps.Type; header: string; message: string }) => {
      // Avoid duplicating notifications with the same content
      if (
        notificationsRef.current.some(
          notification =>
            notification.type === args.type &&
            notification.header === args.header &&
            notification.message === args.message,
        )
      ) {
        return;
      }
      const id = notificationId.current++;

      const dismiss = () =>
        setNotifications(previousNotifications =>
          previousNotifications.filter(notification => notification.id !== `${id}`),
        );

      setNotifications(previousNotifications => [
        ...previousNotifications,
        {
          ...args,
          id: `${id}`,
          dismiss,
        },
      ]);
    },
    [notificationsRef],
  );

  return (
    <NotificationsContext.Provider value={notifications}>
      <AddNotificationsContext.Provider value={addNotification}>{props.children}</AddNotificationsContext.Provider>
    </NotificationsContext.Provider>
  );
}
