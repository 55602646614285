import { Alert, Box, Container, Modal, SpaceBetween, Toggle } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Mode, applyMode } from '@amzn/awsui-global-styles';
import { FeatureFlags, useFeatureFlag } from '../context/FeatureFlags';
import { useAppSettings } from '../context/AppSettings';
import CopyText from './primitives/CopyText';
import { useLocalStorage } from '../hooks/useLocalStorage';

export function SettingsModal(props: { readonly visible: boolean; readonly closeModal: () => void }) {
  const [graphqlApiFeatureFlag, setGraphqlApiFeatureFlag] = useFeatureFlag(FeatureFlags.graphqlApi);
  const { apiUrl, graphqlApiUrl } = useAppSettings();

  const [darkMode, setDarkMode] = useLocalStorage(
    'darkMode',
    window.matchMedia('(prefers-color-scheme: dark)').matches,
  );

  /** Sync dark mode with global styles */
  React.useEffect(() => {
    applyMode(darkMode ? Mode.Dark : Mode.Light);
  }, [darkMode]);

  return (
    <Modal header='GSOC Settings' visible={props.visible} onDismiss={props.closeModal}>
      <SpaceBetween size='l'>
        <ValueWithLabel label='GSOC API URL'>
          <CopyText copyText={apiUrl} />
        </ValueWithLabel>
        <ValueWithLabel label='GraphQL API URL'>
          <CopyText copyText={graphqlApiUrl} />
        </ValueWithLabel>
        <Container
          header='General'
          variant='stacked'
          footer={
            <Alert statusIconAriaLabel='Info' type='info'>
              Clearing browser cache or opening in a new browser will reset all feature flags to defaults.
            </Alert>
          }
        >
          <SpaceBetween size='l'>
            <Toggle checked={!!darkMode} onChange={({ detail }) => setDarkMode(detail.checked)}>
              Use Dark Mode UI
            </Toggle>
            <Toggle checked={graphqlApiFeatureFlag} onChange={e => setGraphqlApiFeatureFlag(e.detail.checked)}>
              Use Data-Mesh
            </Toggle>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </Modal>
  );
}

function ValueWithLabel(props: { readonly label: string; readonly children: React.ReactNode | React.ReactNode[] }) {
  return (
    <div>
      <Box variant='awsui-key-label'>{props.label}</Box>
      <div>{props.children}</div>
    </div>
  );
}
