export enum QueryKeys {
  GROUND_STATION_LIST = 'groundstationList',
  CONTACT_LIST = 'contacts',
  VISUALIZATION_URLS = 'visualizationUrls',
  CONTACT_INFORMATION = 'contactInformation',
  CONTACT_DATAFLOWS = 'contactDataflows',
  POST_PASS_VISUALIZATIONS = 'postPassVisualizations',
  AGENT_POST_PASS = 'agentPostPass',
  CONTACT_METADATA = 'contactMetaData',
  APP_SETTINGS = 'appSettings',
  MIDWAY = 'midway',
  PNG_IMAGE = 'pngImage',
  LIST_ROLES = 'listRoles',
  GSOC_CREDENTIALS = 'gsocCredentials',
  SITE_LISTINGS = 'antennaSiteListings',
  SITE_DETAILS = 'antennaSiteDetails',
  SITE_DETAIL = 'antennaSiteDetail',
  LIST_MAINTENANCE = 'listMaintenance',
  DESCRIBE_MAINTENANCE = 'describeMaintenance',
}
