import * as React from 'react';
import type { fetchMidwayToken } from '../datafetchers/fetchMidwayToken';

export const MidwayContext = React.createContext<Awaited<ReturnType<typeof fetchMidwayToken>> | null>(null);

/** Use the midway token from the Midway context */
export function useMidwayToken(): string {
  const context = React.useContext(MidwayContext);
  if (context === null) {
    throw new Error('useMidwayToken must be used within a MidwayProvider');
  }
  return context.token;
}

/** Use the username from the Midway context */
export function useUsername(): string {
  const context = React.useContext(MidwayContext);
  if (context === null) {
    throw new Error('useUsername must be used within a MidwayProvider');
  }
  return context.decodedToken.sub;
}
