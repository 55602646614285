import jwtDecode from 'jwt-decode';

const MIDWAY_URL = 'https://midway-auth.amazon.com/SSO';
const ID_TOKEN = 'id_token';
const STATE = 'state';

/** Get a midway token */
export async function fetchMidwayToken(opts?: RequestInit): Promise<{
  token: string;
  decodedToken: { exp: number; sub: string };
}> {
  console.info('Fetching Midway Token');
  const searchParams = new URLSearchParams({
    redirect_uri: window.location.toString(),
    client_id: window.location.host,
    scope: 'openid',
    response_type: ID_TOKEN,
    nonce: randomString() + randomString(),
  });
  const res = await fetch(`${MIDWAY_URL}?${searchParams.toString()}`, {
    credentials: 'include',
    signal: AbortSignal.timeout(1000),
    ...opts,
  });

  if (res.status === 401) {
    window.location.replace(`${MIDWAY_URL}/redirect?${searchParams.toString()}`);
  }
  removeMidwaySearchParams();
  const token = await res.text();
  return {
    token,
    decodedToken: jwtDecode<{ exp: number; sub: string }>(token),
  };
}

/**
 * Removes the passed keys from the current location search (query)
 * @param keys
 * @returns a new location without the keys
 */
function removeLocationSearchParameters(...keys: string[]): string {
  const location = window.location;
  const queryParams = new URLSearchParams(location.search);
  keys.forEach(key => queryParams.delete(key));
  const queryString = queryParams.toString();
  const base = location.href.replace(location.search, '');
  const newUrl = `${base}${queryString === '' ? '' : '?'}${queryString}`;
  return newUrl;
}

/**
 * Removes from current location the URL params retuned by Midway (e.g. 'id_token',
 * 'state') after login redirection and updates browser history so they do not
 * appear in the current URL
 */
function removeMidwaySearchParams(): void {
  const newLocation = removeLocationSearchParameters(ID_TOKEN, STATE);
  window.history.replaceState({}, '', newLocation);
}

/** Generate a random string */
function randomString(): string {
  return Math.random().toString(36).substring(2);
}
