import type { AwsCredentialIdentity } from '@aws-sdk/types';
import type { PermissionClaim } from '../code-generated/PermissionClaim.generated';
import { COMMON_CFN_READ_ONLY_ROLE_NAME } from '../utils/gsRoleUtils';

export class ClientManager<Client> {
  protected readonly clientMap = new Map<string, Client>();

  protected readonly gsocRegion: string;
  protected readonly stage: string;
  protected readonly username: string;
  protected readonly credentials: AwsCredentialIdentity;
  protected readonly clientRole: string;
  public readonly permission?: PermissionClaim;

  public constructor(props: {
    gsocRegion: string;
    username: string;
    stage: string;
    credentials: AwsCredentialIdentity;
    clientRole?: string;
    permission?: PermissionClaim;
  }) {
    this.gsocRegion = props.gsocRegion;
    this.stage = props.stage;
    this.username = props.username;
    this.credentials = props.credentials;
    this.clientRole = props.clientRole ?? COMMON_CFN_READ_ONLY_ROLE_NAME;
    this.permission = props.permission;
  }
}
