import { Alert } from '@amzn/awsui-components-react';

/** Generic error display */
export function ErrorDisplay() {
  return (
    <Alert type='error'>
      <h3>Oops something went unexpectedly wrong.</h3>
    </Alert>
  );
}
