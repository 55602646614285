export class AssertionError extends Error {}

export function assertDefined<T>(item: T | undefined | null, message?: string): T {
  if (item === null || item === undefined) {
    throw new AssertionError(
      message ??
        `Expected item to be defined, but item was ${item === undefined ? 'undefined' : 'null'}. This is a bug.`,
    );
  }
  return item as T;
}
