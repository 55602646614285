import * as React from 'react';

import type { FlashbarProps } from '@amzn/awsui-components-react';

export interface Notification {
  type: FlashbarProps.Type;
  header: string;
  message: string;
  id: string;
  dismiss: () => void;
}

// We split our context into reading the notifications and adding a notification.
// This is because we don't want any component writing a notification to rerender on every notification addition,
// just the components that are reading and displaying the notifications.
export const AddNotificationsContext = React.createContext<
  ((args: { type: FlashbarProps.Type; header: string; message: string }) => void) | null
>(null);

export const NotificationsContext = React.createContext<Notification[] | null>(null);

export function useNotifications() {
  const context = React.useContext(NotificationsContext);
  if (context === null) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
}

export function useAddNotification() {
  const context = React.useContext(AddNotificationsContext);
  if (context === null) {
    throw new Error('useAddNotification must be used within a NotificationsProvider');
  }
  return context;
}
