import * as React from 'react';
import { FEATURE_FLAG_DEFAULTS, FeatureFlags, FeatureFlagsContext } from './FeatureFlags';
import { useLocalStorage } from '../hooks/useLocalStorage';

/** FeatureOptIn context  to manage feature opt-in flags */
export function FeatureFlagsProvider(props: { readonly children: React.ReactNode }) {
  const dependencies: unknown[] = [];
  const contextValues = {} as Record<FeatureFlags, [boolean, React.Dispatch<React.SetStateAction<boolean>>]>;
  for (const featureFlag of Object.values(FeatureFlags)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [featureFlagValue, setFeatureFlagValue] = useLocalStorage(
      `FEATURE-FLAG:${featureFlag}`,
      FEATURE_FLAG_DEFAULTS[featureFlag],
    );
    dependencies.push(featureFlagValue, setFeatureFlagValue);
    contextValues[featureFlag] = [
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      featureFlagValue,
      setFeatureFlagValue,
    ];
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const providedValue = React.useMemo(() => contextValues, dependencies);

  return <FeatureFlagsContext.Provider value={providedValue}>{props.children}</FeatureFlagsContext.Provider>;
}
