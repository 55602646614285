import type { AwsRum } from 'aws-rum-web';
import * as React from 'react';

export interface IInstrumentation {
  rumClient: AwsRum | null;
}

export const InstrumentationContext = React.createContext<IInstrumentation>({ rumClient: null });

/**
 * Use the InstrumentationContext
 * @returns IInstrumentation
 */
export function useInstrumentation(): IInstrumentation {
  return React.useContext(InstrumentationContext);
}
