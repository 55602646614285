import { Icon, Spinner } from '@amzn/awsui-components-react';
import { useQuery } from '@tanstack/react-query';
import type * as React from 'react';
import { CenteredFullPageMessage } from '../components/primitives/CenteredFullPageMessage';
import { QueryKeys } from '../constants/queryKeys';
import type { AppSettings } from './AppSettings';
import { AppSettingsContext } from './AppSettings';

const RETRY_ATTEMPTS = 3;

/**
 * AppSettungs context.
 *
 * Used to load and provide settings to the application.
 *
 * @param children Nested components
 * @returns
 */
export function AppSettingsProvider(props: { readonly children: React.ReactNode }) {
  const appSettingsQuery = useQuery({
    queryKey: [QueryKeys.APP_SETTINGS],
    queryFn: async ({ signal }) => {
      const response = await fetch(`${globalThis.location.origin}/settings.json`, { signal });
      return (await response.json()) as AppSettings;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: RETRY_ATTEMPTS,
    onError: err => {
      console.error('Unable to load application settings', err);
    },
  });

  return (
    <AppSettingsContext.Provider value={appSettingsQuery.data ?? null}>
      {appSettingsQuery.isLoading ? (
        <CenteredFullPageMessage>
          <Spinner size='large' />
          Loading application settings...
        </CenteredFullPageMessage>
      ) : appSettingsQuery.isError ? (
        <CenteredFullPageMessage>
          <Icon name='close' size='large' variant='error' />
          Unable to load application settings. Perhaps you forgot to add public/settings.json during local development.
        </CenteredFullPageMessage>
      ) : (
        props.children
      )}
    </AppSettingsContext.Provider>
  );
}
