import * as React from 'react';
import type {
  GsocIamRoleFragment,
  GsocAwsTemporaryCredentialsFragment,
} from '../code-generated/graphqlOperations.generated';
import type { PermissionClaim } from '../code-generated/PermissionClaim.generated';

export const NOT_LOADED = Symbol('NOT_LOADED');

export const PermissionsContext = React.createContext<{
  roles: readonly GsocIamRoleFragment[] | typeof NOT_LOADED;
  setCazJustification: ((cazJustification: string | null) => void) | null;
  cazJustification: string | null;
}>({
  roles: NOT_LOADED,
  setCazJustification: null,
  cazJustification: null,
});

export function useCredentialsForPermissionClaim(
  permissionClaim: PermissionClaim,
): GsocAwsTemporaryCredentialsFragment | typeof NOT_LOADED | null {
  const { roles } = React.useContext(PermissionsContext);
  if (roles === NOT_LOADED) return NOT_LOADED;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  const credentials = roles.find(role => role.name === permissionClaim)?.awsTemporaryCredentials;
  return structuredClone(credentials) ?? null;
}

export function useDoesUserHavePermissionClaim(claim: PermissionClaim): boolean | typeof NOT_LOADED {
  const { roles } = React.useContext(PermissionsContext);
  if (roles === NOT_LOADED) return NOT_LOADED;
  return roles.some(role => (role.name as PermissionClaim) === claim && role.isAuthorized);
}

export function useDoesUserHavePermissionClaims(
  claims: PermissionClaim[],
  operator: 'and' | 'or' = 'and',
): boolean | typeof NOT_LOADED {
  const { roles } = React.useContext(PermissionsContext);
  if (roles === NOT_LOADED) return NOT_LOADED;
  if (operator === 'and') {
    return claims.every(claim => roles.some(role => (role.name as PermissionClaim) === claim && role.isAuthorized));
  } else {
    return claims.some(claim => roles.some(role => (role.name as PermissionClaim) === claim && role.isAuthorized));
  }
}

export function usePermissionClaims(): readonly PermissionClaim[] | typeof NOT_LOADED {
  const { roles } = React.useContext(PermissionsContext);
  if (roles === NOT_LOADED) return NOT_LOADED;
  return roles.filter(c => c.isAuthorized).map(r => r.name as PermissionClaim);
}

export function useIamRoles(): readonly GsocIamRoleFragment[] | typeof NOT_LOADED {
  const { roles } = React.useContext(PermissionsContext);
  return roles;
}

export function useCazJustification(): [string | null, (cazJustification: string | null) => void] {
  const { cazJustification, setCazJustification } = React.useContext(PermissionsContext);
  if (setCazJustification === null) {
    throw new Error('useSetCazJustification must be used within a PermissionsProvider');
  }
  return [cazJustification, setCazJustification];
}
