import * as React from 'react';
import type { InputProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import { Button, Form, FormField, Header, Input, Modal } from '@amzn/awsui-components-react';
import { useCazJustification } from '../context/Permissions';
import { useGsocListIamRolesLazyQuery } from '../code-generated/graphqlOperations.generated';

export function ContingentAuthDialog(props: { readonly onDisable?: () => void; readonly onEnable?: () => void }) {
  const { onDisable, onEnable } = props;
  const [cazJustification, setCazJustification] = useCazJustification();
  const [errorState, setErrorState] = React.useState<string | null>(null);
  const [cazValue, setCazValue] = React.useState<string>(cazJustification ?? '');

  const [listIamRolesQuery, { loading }] = useGsocListIamRolesLazyQuery();

  const enableOnClick = React.useCallback(async () => {
    // First make sure that contingent auth passes, then set the cazJustification globally
    const res = await listIamRolesQuery({
      variables: {
        cazJustification: cazValue,
      },
    });
    if (res.error !== undefined) {
      setErrorState('Contingent auth provided is invalid. Please use an active ticket id.');
    }
    if (res.data !== undefined) {
      setCazJustification(cazValue);
      setErrorState(null);
      onEnable?.();
    }
  }, [listIamRolesQuery, cazValue, setErrorState, setCazJustification, onEnable]);

  const disableOnClick = React.useCallback(() => {
    setCazJustification(null);
    setErrorState(null);
    onDisable?.();
  }, [setCazJustification, setErrorState, onDisable]);

  const inputOnChange = React.useCallback(
    ({ detail }: NonCancelableCustomEvent<InputProps.ChangeDetail>) => setCazValue(detail.value),
    [setCazValue],
  );

  return (
    <Form
      header={<p>Please provide a ticket id to enable access to operations protected by contingent authorization</p>}
      actions={
        cazJustification === null ? (
          <Button
            data-testid='caz-justification-enable'
            variant='primary'
            disabled={cazValue === ''}
            loading={loading}
            onClick={enableOnClick}
          >
            Enable CAZ
          </Button>
        ) : (
          <Button data-testid='caz-justification-disable' variant='primary' onClick={disableOnClick}>
            Disable CAZ
          </Button>
        )
      }
    >
      <FormField errorText={errorState}>
        <Input value={cazValue} placeholder='Ticket ID' disabled={cazJustification !== null} onChange={inputOnChange} />
      </FormField>
    </Form>
  );
}

export function ContingentAuthModal(props: { readonly visible: boolean; readonly closeModal: () => void }) {
  return (
    <Modal
      header={<Header variant='h2'>Contingent Authorization [CAZ]</Header>}
      visible={props.visible}
      onDismiss={props.closeModal}
    >
      <ContingentAuthDialog onDisable={props.closeModal} onEnable={props.closeModal} />
    </Modal>
  );
}
