import type { StatusIndicatorProps } from '@amzn/awsui-components-react';
import { Box, Button, Popover, StatusIndicator } from '@amzn/awsui-components-react';
import * as React from 'react';
import { styled } from '@linaria/react';
import { copyToClipboard } from '../../utils/copyToClipboard';

const SUCCESS_STATUS = 'success';
const ERROR_STATUS = 'error';

/**
 * CopyText is a component that allows a user to copy text to their clipboard
 */
export default function CopyText(props: {
  readonly copyText: string;
  readonly copyButtonLabel?: string | undefined;
  readonly successText?: string | undefined;
  readonly errorText?: string | undefined;
  readonly hideText?: boolean | undefined;
}) {
  const [status, setStatus] = React.useState<StatusIndicatorProps.Type | undefined>(SUCCESS_STATUS);
  const [message, setMessage] = React.useState<string>(props.successText ?? 'Copied');

  return (
    <WordWrapBreakAllDiv>
      <Box margin={{ right: 'xxs' }} display='inline-block'>
        <Popover
          size='small'
          position='top'
          dismissButton={false}
          triggerType='custom'
          content={<StatusIndicator type={status}>{message}</StatusIndicator>}
        >
          <Button
            variant='inline-icon'
            iconName='copy'
            ariaLabel={props.copyButtonLabel ?? 'Copy'}
            onClick={() => {
              copyToClipboard(props.copyText).then(
                () => {
                  setStatus(SUCCESS_STATUS);
                  setMessage(props.successText ?? 'Copied');
                },
                () => {
                  setStatus(ERROR_STATUS);
                  setMessage(props.errorText ?? 'Failed to copy');
                },
              );
            }}
          />
        </Popover>
      </Box>
      {props.hideText ? '' : props.copyText}
    </WordWrapBreakAllDiv>
  );
}

const WordWrapBreakAllDiv = styled.div`
  word-break: break-all;
`;
