export async function readStreamToArrayBuffer(stream: ReadableStream<Uint8Array>): Promise<Uint8Array> {
  const inputReader = stream.getReader();

  const parts: Uint8Array[] = [];

  let isReadDone = false;
  while (!isReadDone) {
    const readResult = await inputReader.read();
    isReadDone = readResult.done;
    if (readResult.value !== undefined) {
      parts.push(readResult.value);
    }
  }

  // Allocate ArrayBuffer
  const totalLength = parts.reduce((accum, part) => accum + part.byteLength, 0);
  const arrayBuffer = new Uint8Array(totalLength);

  // Set the arrayBuffer parts
  let currentOffset = 0;
  for (const part of parts) {
    arrayBuffer.set(part, currentOffset);
    currentOffset += part.byteLength;
  }

  return arrayBuffer;
}
