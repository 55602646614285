import * as tokens from '@amzn/awsui-design-tokens/polaris.js';

import { styled } from '@linaria/react';

export const CenteredFullPageMessage = styled.div`
  background-color: ${tokens.colorBackgroundLayoutMain};
  color: ${tokens.colorTextBodyDefault};
  font-family: ${tokens.fontFamilyBase};
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;
