import * as React from 'react';

export function useModalState(initialState?: boolean): [boolean, { open: () => void; close: () => void }] {
  const [modalState, setModalState] = React.useState(initialState ?? false);

  const actions = React.useMemo(
    () => ({
      open: () => setModalState(true),
      close: () => setModalState(false),
    }),
    [setModalState],
  );

  return [modalState, actions];
}
