import * as React from 'react';
import type { AppLayoutProps, BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import { AppLayout, BreadcrumbGroup, Flashbar } from '@amzn/awsui-components-react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';
import { useNotifications } from '../context/Notifications';
import { NavigationSidebar } from './NavigationSidebar';
import { ErrorDisplay } from './primitives/ErrorDisplay';

/**
 * CommonAppLayout allows the app to have some default settings for AppLayout
 * such as the navigation, hiding tools, breadcrumbs, and notifications
 */
export function CommonAppLayout(
  props: Omit<AppLayoutProps, 'breadcrumbs'> & {
    readonly breadcrumbs: BreadcrumbGroupProps.Item[];
  },
) {
  const [navigationOpen, setNavigationOpen] = React.useState(props.navigationOpen ?? true);
  const history = useHistory();

  return (
    <div style={{ marginTop: document.getElementById('h')?.offsetHeight ?? 56 }}>
      <AppLayout
        {...props}
        stickyNotifications
        navigation={<NavigationSidebar />}
        content={props.content}
        breadcrumbs={
          <BreadcrumbGroup
            items={props.breadcrumbs}
            expandAriaLabel='Show path'
            ariaLabel='Breadcrumbs'
            onFollow={ev => {
              ev.preventDefault();
              history.push(ev.detail.item.href);
            }}
          />
        }
        notifications={<NotificationPane />}
        toolsHide={props.tools === undefined}
        navigationOpen={navigationOpen}
        onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      />
    </div>
  );
}

function NotificationPane() {
  const notifications = useNotifications();
  return (
    <ErrorBoundary FallbackComponent={ErrorDisplay}>
      <Flashbar
        items={notifications.map(item => {
          return {
            header: item.header,
            type: item.type,
            content: item.message,
            dismissible: true,
            id: item.id,
            onDismiss: item.dismiss,
          };
        })}
      />
    </ErrorBoundary>
  );
}
