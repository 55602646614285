import { fromTemporaryCredentials } from '@aws-sdk/credential-providers';
import type { AwsCredentialIdentity, AwsCredentialIdentityProvider } from '@aws-sdk/types';

export const READ_ONLY_GSOC_ROLE_NAME = 'AwsGsaasGSOCOpsReadOnly';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const READ_WRITE_GSOC_ROLE_NAME = 'AwsGsaasGSOCOpsReadWrite';

export function buildAwsTemporaryCredentialProvider(props: {
  roleArn: string;
  username: string;
  region: string;
  masterCredentials: AwsCredentialIdentity;
}): AwsCredentialIdentityProvider {
  // Will provide a ChainTemporaryCredentials
  // The masterCredentials will be used to assume the passed in roleArn.
  const sessionName = `${props.username}-OpsConsole`;
  return fromTemporaryCredentials({
    params: {
      RoleArn: props.roleArn,
      RoleSessionName: sessionName,
    },
    masterCredentials: props.masterCredentials,
    // This client config must point to the STS global endpoint (us-east-1)
    // TODO: Regionalize the STS endpoint
    clientConfig: {
      region: props.region,
    },
  });
}
