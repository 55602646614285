import AWS_ACCOUNTS from '@amzn/aws-gsaas-accounts-helper/dist/configuration/aws_gsaas_aws_accounts_config/aws_accounts.json';
import type { AccountsConfig } from '@amzn/aws-gsaas-accounts-helper/dist/account-helper-browser';
import { AccountHelperBrowser } from '@amzn/aws-gsaas-accounts-helper/dist/account-helper-browser';

export const accountsHelper = new AccountHelperBrowser(AWS_ACCOUNTS as unknown as AccountsConfig);

export const GSOC_NAME_PART = 'opsconsole';
export const ANALYTICS_NAME_PART = 'analytics';
export const CANARY_NAME_PART = 'canary';

export enum ACCOUNT_TYPE {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
  Canary = 'CANARY',
}

export const INTERNAL_ACCOUNT = 'INTERNAL';
export const EXTERNAL_ACCOUNT = 'EXTERNAL';
export const CANARY_ACCOUNT = 'CANARY';

export function getAccountNamePart(accountId: string): string {
  if (accountsHelper.accountIds.includes(accountId)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return accountsHelper.awsAccounts[accountId]!.name_part;
  } else {
    return ACCOUNT_TYPE.External;
  }
}

export function getAccountName(accountId: string | null): string | null {
  if (accountId === null) return null;
  return accountsHelper.awsAccounts[accountId]?.name ?? null;
}

export function getAccountType(namePart: string): ACCOUNT_TYPE {
  if (namePart === EXTERNAL_ACCOUNT) {
    return ACCOUNT_TYPE.External;
  }
  if (namePart.includes(CANARY_NAME_PART)) {
    return ACCOUNT_TYPE.Canary;
  }
  return ACCOUNT_TYPE.Internal;
}
