import * as React from 'react';

/** START: ADD FEATURE FLAGS HERE */
export enum FeatureFlags {
  graphqlApi = 'graphqlApi',
}

export const FEATURE_FLAG_DEFAULTS: Record<FeatureFlags, boolean> = {
  [FeatureFlags.graphqlApi]: true,
};
/** END: ADD FEATURE FLAGS HERE */

export const FeatureFlagsContext = React.createContext(
  (() => {
    const flagContext = {} as Record<FeatureFlags, [boolean, React.Dispatch<React.SetStateAction<boolean>>]>;
    for (const featureFlag of Object.values(FeatureFlags)) {
      flagContext[featureFlag] = [
        FEATURE_FLAG_DEFAULTS[featureFlag],
        () => {
          /* Intentionally Empty */
        },
      ];
    }
    return flagContext;
  })(),
);

/** Use a feature flag */
export function useFeatureFlag(featureName: FeatureFlags): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const context = React.useContext(FeatureFlagsContext);
  return context[featureName];
}
