import * as React from 'react';
import { S3WithErrorRefinement } from '../utils/s3';
import { buildAwsTemporaryCredentialProvider } from '../clients/buildAwsTemporaryCredentialProvider';
import { ANALYTICS_NAME_PART, accountsHelper } from '../utils/accountsUtils';
import { DATA_ANALYTICS_READ_ONLY_ROLE_NAME, getRoleArn } from '../utils/gsRoleUtils';
import { LegacyIamRole, useLegacyIamRoleCredentials } from './LegacyIamRoles';
import { AnalyticsAccountS3ClientContext } from './AnalyticsAccountS3Client';
import { useAppSettings } from './AppSettings';
import { useUsername } from './Midway';

/**
 * S3Client context.
 *
 * Used to create and provide a, S3Client to the application.
 *
 * @param children Nested components
 * @returns
 */
export function AnalyticsAccountS3ClientProvider(props: { readonly children: React.ReactNode }) {
  const { region, stage } = useAppSettings();
  const username = useUsername();
  const gsocReadonlyCredentials = useLegacyIamRoleCredentials(LegacyIamRole.AwsGsaasGSOCOpsReadOnly);

  const analyticsAccountS3Client = React.useMemo(() => {
    if (gsocReadonlyCredentials === null) return null;
    const analyticAccount = accountsHelper.getAccount(ANALYTICS_NAME_PART, stage, region);
    if (!analyticAccount) {
      console.error(new Error(`No analytics account found for stage ${stage} and region ${region}.`));
      return null;
    }
    const analyticAccountId = analyticAccount.account_id;
    const roleArn = getRoleArn(analyticAccountId, DATA_ANALYTICS_READ_ONLY_ROLE_NAME);

    return new S3WithErrorRefinement({
      credentials: buildAwsTemporaryCredentialProvider({
        roleArn,
        username,
        masterCredentials: {
          accessKeyId: gsocReadonlyCredentials.accessKeyId,
          secretAccessKey: gsocReadonlyCredentials.secretAccessKey,
          sessionToken: gsocReadonlyCredentials.sessionToken,
          expiration: gsocReadonlyCredentials.expiration,
        },
        region,
      }),
      region,
    });
  }, [gsocReadonlyCredentials, region, stage, username]);

  return (
    <AnalyticsAccountS3ClientContext.Provider value={analyticsAccountS3Client}>
      {props.children}
    </AnalyticsAccountS3ClientContext.Provider>
  );
}
