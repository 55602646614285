import * as React from 'react';

export function useLocalStorage<T>(
  key: string,
  defaultValue: T,
  options?: {
    serializer?: (value: T) => string;
    deserializer?: (value: string) => T;
  },
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = React.useState(() => {
    const localStorageItem = globalThis.localStorage.getItem(key);
    if (localStorageItem === null) {
      globalThis.localStorage.setItem(key, (options?.serializer ?? JSON.stringify)(defaultValue));
      return defaultValue;
    }
    return (options?.deserializer ?? JSON.parse)(localStorageItem) as T;
  });

  React.useEffect(() => {
    globalThis.localStorage.setItem(key, (options?.serializer ?? JSON.stringify)(value));
  }, [value, key, options?.serializer]);

  return [value, setValue];
}
